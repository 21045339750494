import React from 'react';

const Product = (props) => (
            <div>
                <h5 className="lineCardLink pt-5">Check out our <a href='https://www.youtube.com/@PartnersforProcess' target='_blank' rel="noopener noreferrer" className='mannavlink'>YouTube</a> channel for more information on products and material handling solutions!</h5>                
                <div className = "productPanel">
                <img src={props.doc} style={{marginTop: '5rem'}} alt=''/>
                </div>
                <div className="productPanelGraph">
                {props.subDoc && <img src={props.subDoc} alt=''></img>}
                </div>
            </div>
        )
    
export default Product;