import React from 'react';
import { Modal, Button } from "react-bootstrap";


const DownloadModal =(props)=>(
    <div>
    <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Download Our Linecard</Modal.Title>
        </Modal.Header>
        <Modal.Body>Would you like to download a pdf of our linecard?</Modal.Body>
        <Modal.Footer>
            <Button onClick={props.handleDownload}>Yes</Button>
            <Button onClick={props.handleClose}>No</Button>

        </Modal.Footer>

    </Modal>
    </div>
)

export default DownloadModal;