import React from 'react';

const Manlink = (props) => (

            <div className = "manlink m-3"> 
                <a href = {props.href} target = "_blank" rel="noopener noreferrer">
                    <img src = {props.src} alt = {props.alt} className="prodimg" />
                </a>
            </div>

)

export default Manlink