import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import ProductNav from './ProductNav'
import GravimetricFeederInfo from '../images/GravimetricFeederInfo.png'
import GravimetricFeederChart from '../images/GravimetricFeederChart.png'
import WhisperLoader from '../images/WhisperLoaders.png'
import WhisperLoaderChart from '../images/WhisperLoadersChart.png'
import BulkBagEquip from '../images/BulkBagEquip.png'
import BeltFeeder from '../images/BeltFeeder.png'
import BeltFeeder2 from '../images/BeltFeeder2.png'
import VolumetricFeederInfo from '../images/VolumetricFeeder.png'
import VolumetricFeederChart from '../images/VolumetricFeederChart.png'




import ManNav from './ManNav';

import Contact from './Contact'
import Product from './Product'





class AllProducts extends React.Component{


    render(){
        return (
            <BrowserRouter basename="/#/AllProducts">
            <div className="row prodpage">
                <div className="col-xl-2 col-lg-3 col-md-12 col-sm-12" >
                    <ProductNav />
                </div>
                <div className = "col-xl-10 col-lg-9 col-md-12 col-sm-12 prodback">
                    <div>
                    <Switch>
                        <Route exact path = "/" render = {()=> <ManNav />} />
                        <Route exact path = "/BeltFeeder" render = {()=> <Product doc={BeltFeeder} subDoc={BeltFeeder2} />} />
                        <Route exact path = "/VolumetricFeeder" render = {()=> <Product doc={VolumetricFeederInfo} subDoc={VolumetricFeederChart}/>} />
                        <Route exact path = "/GravimetricFeeder" render = {()=> <Product doc={GravimetricFeederInfo} subDoc={GravimetricFeederChart}/>} />
                        <Route exact path = "/WhisperLoader" render = {()=> <Product doc={WhisperLoader} subDoc={WhisperLoaderChart}/>} />
                        <Route exact path = "/BulkBag" render = {()=> <Product doc={BulkBagEquip} />} />
                        
                        {/*
                        <Route exact path = "/Loaders" render = {()=> <Product label="Powder & Whisper Loaders" description="" />} />
                        <Route exact path = "/Receivers" render = {()=> <Product label="Receivers" description="" />} />
                        <Route exact path = "/Controls" render = {()=> <Product label="Controls" description="" />} />
                        <Route exact path = "/Blowers" render = {()=> <Product label="Blowers" description="" />} />
                        <Route exact path = "/Batchers" render = {()=> <Product label="Batchers" description="" />} />
                        <Route exact path = "/Loaders" render = {()=> <Product label="Bulk Bag Loaders" description="" />} />
                        <Route exact path = "/DumpStation" render = {()=> <Product label="Dump Stations" description="" />} />
                        <Route exact path = "/Systems" render = {()=> <Product label="Complete Systems" description="" />} />
                        <Route exact path = "/Fillers" render = {()=> <Product label="Bulk Bag Fillers" description="" />} />
                        <Route exact path = "/Gates" render = {()=> <Product label="Control Gates" description="" />} /> */}
                        <Route exact path = "/Contact" render = {()=> <Contact />} />


                    </Switch>
                    </div>
                </div>
            </div>
            </BrowserRouter>
            

        )
    }
}

export default AllProducts;