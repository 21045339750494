import React from 'react';
import { Link } from "react-router-dom";

class ProductNav extends React.Component{

    render(){
        return (
                <div className="text-center product-nav">
                    <div className="prodtop">
                        <div style={{"height": "0.5rem"}}></div>
                        <Link to={{pathname:'/'}}>
                        <h3 className="prodtopcapt">Products</h3>
                        </Link>
                    </div>
                    <div className="prodcat">
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/BeltFeeder"}}>
                        <div className="grey">Belt Feeders</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/VolumetricFeeder"}}>
                        <div className="grey">Volumetric Feeders</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/GravimetricFeeder"}}>
                        <div className="grey">Gravimetric Feeders</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/WhisperLoader"}}>
                        <div className="grey">Whisper Loaders</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/BulkBag"}}>
                        <div className="grey">Bulk Bag Equipment</div>
                    </Link>
                    </div>

                    {/*
                    <div className="prodcat">
                    <Link to={{pathname:"/Processors"}}>
                        <div className="grey">Processors</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/Gates"}}>
                        <div className="grey">Control Gates</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/Loaders"}}>
                        <div className="grey">Powder & Whisper Loaders</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/Receivers"}}>
                        <div className="grey">Receivers</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/Systems"}}>
                        <div className="grey">Systems</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/Controls"}}>
                        <div className="grey">Controls</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/Batchers"}}>
                        <div className="grey">Batchers</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/Blowers"}}>
                        <div className="grey">Blowers</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/Fillers"}}>
                        <div className="grey">Bulk Bag Fillers</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/Unloaders"}}>
                        <div className="grey">Bulk Bag Unloaders</div>
                    </Link>
                    </div>
                    <div className="prodcat">
                    <Link to={{pathname:"/DumpStation"}}>
                        <div className="grey">Dump Stations</div>
                    </Link> 
        </div> */}

        
                </div>
        )
    }
}

export default ProductNav;