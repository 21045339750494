import React from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './App.css';
import Nav from './components/Nav'
import Manufacturers from './components/Manufacturers.js'
import ContactTab from './components/ContactTab.js'
import About from './components/About.js'
import AllProducts from './components/AllProducts';
import DownloadModal from './components/DownloadModal';
import axios from 'axios';
import fileDownload from 'js-file-download';





class App extends React.Component {

  state = {
    shodalModal: false
  }

  showModal =()=>{
    this.setState({
      showModal: true
    })
  }

  handleClose =()=>{
    this.setState({
        showModal: false
    })
  }

  handleDownload =()=>{
    this.setState({
        showModal: false
    })
    axios.get('/files/LineCard2024.pdf', {
      responseType: 'blob'
    })
    .then((res)=> {
      fileDownload(res.data, 'partners_for_process_linecard.pdf')
    })
  }


  render (){
      return (
      <HashRouter>
        <div className="container-fluid">
        <div className="topNav">
        <Nav download={this.showModal}/>
        </div>
        <div className="app">
        <Switch>
          <Route exact path="/" render = {() => <About />} />
          <Route exact path="/Manufacturers" render = {() => <Manufacturers/>} />
          <Route exact path="/ContactTab" render = {() => <ContactTab/>} />
          <Route exact path="/AllProducts" render = {()=> <AllProducts />} />

        </Switch>
        <DownloadModal 
                            show={this.state.showModal} 
                            handleClose={this.handleClose} 
                            handleDownload={this.handleDownload}
                        />
        </div>
        </div>
        </HashRouter>
      )
    }
}

export default App;
