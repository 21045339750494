import React from 'react';
import Slide from './Slide';


class About extends React.Component{

    render(){
        return (
            <div className="mainback">
                <div className="grad">
                <div className = "row pt-5 mx-1 about grey">
                    <h4><em>Partners For Process</em></h4>
                    <p>Partners for Process is a Father and Son company that helps Manufacturers with Automation Solutions. With over 30 Years of experience in dry material handling, Rich Sarow and Bruce Sarow can give honest advice in your process lines; helping you find replacement components for existing systems or Full Turn-Key Systems. Industries Served: Food, Pet Food, Plastics, Chemicals, Pharmaceutical's, Wood, Mining, Aggregates, and More.</p> 
                </div>
                <div className = "row">
                    <Slide />
                </div>
                <div className = "row pt-5 mx-1 testimonial grey">
                <h4 className="mb-4"><em>Testimonials</em></h4>
                <div>
                    <ul>
                        <li>
                            <p>“From start to finish, the experience with Material Transfer and Partners for Process was fantastic. The communication throughout the purchasing and installation process was excellent. Bruce kept us informed every step of the way and was always quick to answer any questions we had. The equipment itself was incredibly easy to install and works like as promised. The dust collection unit has significantly reduced the amount of dust exposure in our workspace, making our process much more pleasant and efficient. We highly recommend the Bag Stand, Material Transfer, and Partners for Process.”</p>
                        </li>
                    </ul>
                </div>
                </div>
                </div>
            </div>
        )
    }
}

export default About;