import React from 'react';
import tecweigh from '../images/Tecweigh-logo.jpg';
import GWM from '../images/GWM.png'
import Babbit from '../images/Babbit.PNG'
import Manlink from './Manlink';
import Legends from '../images/legends.png';
import Prater from '../images/prater.png';
import materialTransfer from '../images/materialTransfer.png';
import Its from '../images/its.jpg';
import Imperial from '../images/imperial.jpg';
import Sterling from '../images/sterling.png';
import Horizon from '../images/horizon.png';

class ManNav extends React.Component {

    render (){
        return (
            <div className = "text-center my-5 hideSmall"> 

                <h3 className="mannav pt-3">Proudly Offering Products From These Quality Manufacturers:</h3>
                <h5 className="mannav pt-4">Check out our <a href='https://www.youtube.com/@PartnersforProcess' target='_blank' rel="noopener noreferrer" className='mannavlink'>YouTube</a> channel for more information on products and material handling solutions!</h5>
                <div className = "row py-3">
                    <div className="row mangroup">
                    <Manlink src = {materialTransfer} alt = "material transfer" href="https://materialtransfer.com/"/>
                    <Manlink src = {Babbit} alt = "babbit international"  href = "https://www.babbittinternational.com/"/>
                    <Manlink src = {Horizon} alt = "horizon" href="https://horizonsystemsinc.com/"/>
                    <Manlink src = {Imperial} alt = "imperial industries" href="https://www.imperialind.com/"/>
                    </div>
                </div>
                <div className = "row py-4">
                    <div className="row mangroup">
                    <Manlink src = {tecweigh} alt = "tec-weigh" href = "https://www.tecweigh.com/"/>
                    <Manlink src = {Legends} alt = "Legends Equipment" href="https://legendsequipment.com/"/>
                    <Manlink src = {GWM} alt = "Great Western Manufacturing" href="https://gwmfg.com/"/>
                    </div>
                </div>
                <div className = "row pt-4 text-center">
                    <div className="row mangroup">
                    <Manlink src = {Its} alt = "Industrial Tank Services" href="https://industrialtankservices.com/" />
                    <Manlink src = {Sterling} alt = "Sterling Systems and controls" href="https://sterlingcontrols.com/" />
                    <Manlink src = {Prater} alt = "Prater" href="https://www.praterindustries.com/"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ManNav;