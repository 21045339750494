import React from 'react';
import Contact from './Contact'


class ContactTab extends React.Component {

render(){
    return (

    <div className='text-center py-5 grainback' >
    <div>
    <div className = "alert-info mx-auto my-3 alert">
        <h4>Have questions?</h4>
        <p>Fill out the form below. We'll get back to you ASAP!</p>
    </div>
    <Contact />
    </div>
    </div>

    )
    }
}

export default ContactTab