import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/mainLogo.png'

const Nav = (props) => (
    <div>
    <div className = "row logobar">
        <div className = "col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 pt-2">
            <Link to={{pathname: "/"}} className= 'logo'>
                <img src = {logo} alt = "Partners For Process" className="mainlogo" />
            </Link>
            <div className='buttonContainerLogo'>
                <span className="buttonLinksLogo">
                    <a href='https://www.youtube.com/@PartnersforProcess' target='_blank' rel="noopener noreferrer">
                        <button className="btn btn-danger">Check us out on YouTube</button>
                    </a>
                </span>
                <span className="buttonLinksLogo pl-1">
                    <button className="btn btn-primary" onClick={props.download}>Download Our Line Card</button>
                </span>
            </div>
        </div>
        <div className = "col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 contact">
            <div className = 'row'>
                <div className='col-xl-6 col-lg-12 lightgrey pt-1'>
                    <p><strong>Bruce Sarow: Phone: (920) 723-9767</strong></p>
                    <p><strong>Email: bruce@partnersforprocess.com</strong></p>
                </div>
                <div className='col-xl-6 col-lg-12 darkgrey pt-1'>
                    <p><strong>Rich Sarow: Phone: (920) 723-9767</strong></p>
                    <p><strong> Email: rich@partnersforprocess.com</strong></p>
                </div>
            </div>
        </div>
    </div>
    <nav className="navbar navbar-expand-lg navbar-dark nav pr-5">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
    <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
        <ul className = "navbar-nav">
            <li className="nav-item mx-5">
            <Link to={{pathname:"/"}} className = "nav-link">About Us</Link>
            </li>
            <li className="nav-item mx-5">
            <Link to={{pathname: "/AllProducts"}} className = "nav-link">Products</Link>
            </li>
            <li className="nav-item mx-5">
            <Link to={{pathname: "/Manufacturers"}} className = "nav-link">Manufacturers</Link>
            </li>
            <li className="nav-item mx-5">
            <Link to={{pathname: "/ContactTab"}} className = "nav-link">Contact Us</Link>
            </li>
        </ul>
    </div>
    <div className='buttonContainerNav'>
        <span className="buttonLinksNav">
            <a href='https://www.youtube.com/@PartnersforProcess' target='_blank' rel="noopener noreferrer" className='pr-5'><button className="btn btn-danger">Check us out on YouTube</button></a>
        </span>
        <span className="buttonLinksNav">
            <button className="btn btn-primary" onClick={props.download}>Download Our Line Card</button>
        </span>
    </div>
    </nav>
    </div>
)

export default Nav